import { theme } from "ftl-uikit"
import { createTheme } from "@material-ui/core/styles"

export const primaryColor = "#16504C"

export const FoodTechLabCallCenterTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    text: {
      ...theme.palette.text,
      primary: primaryColor,
    },
  },
})