import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory"

import {
  AttachMoney,
  Ballot,
  Category, Extension,
  Fastfood,
  Feedback,
  Instagram,
  LocalActivity,
  LocalOffer,
  MenuBook,
  Notifications, Place, RateReview, Restaurant, Satellite,
  ViewCarousel,
  Wc,
} from "@material-ui/icons"

import { MapIcon, OrderIcon as OrderIcon_, CityIcon as CityIcon_ } from "ftl-uikit"
import { primaryColor } from "../../../Theme"

export const CityIcon = () => {
  return <CityIcon_ />
}

export const RestaurantIcon = () => {
  return <StoreMallDirectoryIcon />
}

export const DeliveryZoneIcon = () => {
  return <Place />
}

export const CatalogIcon = () => {
  return <MenuBook />
}

export const ProductIcon = () => {
  return <Fastfood />
}

export const DishIcon = () => {
  return <Fastfood />
}

export const ConstructorIcon = () => {
  return <Extension />
}

export const NomenclatureIcon = () => {
  return <Ballot />
}

export const PromotionIcon = () => {
  return <LocalActivity />
}

export const BannerGroupIcon = () => {
  return <ViewCarousel />
}

export const BannerIcon = () => {
  return <Satellite />
}

export const StoryIcon = () => {
  return <Instagram />
}

export const OrderIcon = () => {
  return <OrderIcon_ style={{color: primaryColor, transform: "scale(1.2)"}}/>
}

export const CustomerIcon = () => {
  return <Wc />
}

export const FeedbackIcon = () => {
  return <Feedback />
}

export const FeedbackReasonIcon = () => {
  return <RateReview />
}

export const CategoryIcon = () => {
  return <Category />
}

export const PriceCategoryIcon = () => {
  return <LocalOffer />
}

export const PriceIcon = () => {
  return <AttachMoney />
}

export const NotificationIcon = () => {
  return <Notifications />
}

export const ComplectationIcon = () => {
  return <Restaurant />
}

export const FoodTechLabLogo = () => {
  return <svg width="100%" height="100%" viewBox="0 0 177 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2819_196)">
      <path d="M168.955 0H8.04545C3.60207 0 0 3.60207 0 8.04545V168.955C0 173.398 3.60207 177 8.04545 177H168.955C173.398 177 177 173.398 177 168.955V8.04545C177 3.60207 173.398 0 168.955 0Z" fill="#16504C"/>
      <path d="M148.157 38.4954C153.671 32.981 156.428 30.2238 156.615 27.8567C156.777 25.8027 155.945 23.7955 154.378 22.4574C152.573 20.9153 148.673 20.9153 140.875 20.9153H31.2169C27.6122 20.9153 25.8099 20.9153 24.433 21.6168C23.222 22.2339 22.2373 23.2185 21.6202 24.4296C20.9187 25.8064 20.9187 27.6088 20.9187 31.2135V42.7989C20.9187 46.4036 20.9187 48.206 21.6202 49.5828C22.2373 50.7939 23.222 51.7785 24.433 52.3956C25.8099 53.0971 27.6122 53.0971 31.2169 53.0971H129.289C130.864 53.0971 131.651 53.0971 132.392 52.9193C133.048 52.7616 133.677 52.5016 134.252 52.1486C134.902 51.7506 135.458 51.194 136.571 50.0809L148.157 38.4954Z" fill="white"/>
      <path d="M38.4988 148.156C32.9844 153.67 30.2272 156.427 27.8601 156.614C25.8061 156.776 23.7989 155.944 22.4609 154.377C20.9187 152.572 20.9187 148.672 20.9187 140.874V79.4892C20.9187 75.8845 20.9187 74.0822 21.6202 72.7054C22.2373 71.4943 23.222 70.5096 24.433 69.8925C25.8099 69.191 27.6122 69.191 31.2169 69.191H42.8023C46.407 69.191 48.2094 69.191 49.5862 69.8925C50.7973 70.5096 51.782 71.4943 52.399 72.7054C53.1005 74.0822 53.1005 75.8845 53.1005 79.4892V129.289C53.1005 130.863 53.1005 131.65 52.9227 132.391C52.765 133.047 52.505 133.676 52.152 134.251C51.754 134.901 51.1974 135.457 50.0843 136.571L38.4988 148.156Z" fill="white"/>
      <path d="M69.1909 92.6014C69.1909 100.4 69.1909 104.299 70.7331 106.105C72.0711 107.671 74.0782 108.503 76.1322 108.341C78.4993 108.155 81.2565 105.398 86.7708 99.8833L99.8832 86.771C105.397 81.2567 108.155 78.4994 108.341 76.1323C108.503 74.0784 107.671 72.0712 106.105 70.7332C104.299 69.191 100.4 69.191 92.6013 69.191H79.489C75.8843 69.191 74.082 69.191 72.7052 69.8925C71.4941 70.5096 70.5095 71.4943 69.8924 72.7053C69.1909 74.0821 69.1909 75.8845 69.1909 79.4891V92.6014Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_2819_196">
        <rect width="177" height="177" fill="white"/>
      </clipPath>
    </defs>
  </svg>

}