import {lazy} from "react"
import {Switch} from "react-router-dom"

import {FTLErrorPage} from "ftl-uikit"
import {PrivateRoute} from "ftl-core"

//Orders
const OrdersWIthExternalProblems = lazy(() => import("./pages/OrdersWIthExternalProblems/List"))

const OrdersWIthExternalProblemsDetail = lazy(() => import("./pages/OrdersWIthExternalProblems/Detail"))

const OrdersList = lazy(() => import("./pages/Order/List"))
const OrdersDetailPage = lazy(() => import("./pages/Order/Detail"))
const WorkloadPage = lazy(() => import("./pages/Order/WorkloadPage"))
const OrderSettingsPage = lazy(() => import("./pages/OrderSettings/OrderSettingsPage"))


const SmsList = lazy(() => import("./pages/Sms/List"))

export const Routes = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/with-external-problem">
                <OrdersWIthExternalProblems/>
            </PrivateRoute>
            <PrivateRoute exact path="/with-external-problem/:id">
                <OrdersWIthExternalProblemsDetail pageType={"edit"}/>
            </PrivateRoute>
            <PrivateRoute exact path="/orders">
                <OrdersList/>
            </PrivateRoute>
            <PrivateRoute exact path="/orders/workload">
                <WorkloadPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/orders/:id">
                <OrdersDetailPage pageType={"edit"}/>
            </PrivateRoute>
            <PrivateRoute path={"/order-settings"} exact>
                <OrderSettingsPage />
            </PrivateRoute>



          <PrivateRoute exact path="/sms">
            <SmsList />
          </PrivateRoute>


            <PrivateRoute exact path={["*", "/404"]}>
                <FTLErrorPage
                    title="Страницы не существует"
                    message="Наверное, вы перешли по неправильной ссылке."
                />
            </PrivateRoute>
        </Switch>
    )
}
